// set pahse number 0 if exceeded
import { phases } from 'Data/phases'
import { db } from 'firebase'
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc
} from 'firebase/firestore'

export const addNewCard = async (
  note,
  model,
  id,
  phaseNumber,
  incrementNumber
) => {
  // model type
  try {
    const mt = phases[model]
    const routeToGo = mt.routes[phaseNumber]

    // route if incremental
    const collectionRef =
      model === 'incremental'
        ? collection(db, `phases/${id}/${incrementNumber}/${routeToGo}/${id}`)
        : collection(db, `phases/${id}/${routeToGo}`)

    const payload = {
      ...note,
      completed: false,
      timestamp: serverTimestamp()
    }
    await addDoc(collectionRef, payload)
  } catch (error) {
    console.log(error)
  }
}

export const updateProjectStatus = async (project) => {
  try {
    const { id, ...details } = project
    const docRef = doc(db, 'meta', id)
    const payload = { ...details, timestamp: serverTimestamp() }
    updateDoc(docRef, payload)
  } catch (error) {
    console.log(error)
  }
}

export const deleteCard = async (pathRef, card) => {
  try {
    // delete meta
    const cardRef = doc(db, pathRef, card.id)
    await deleteDoc(cardRef)
  } catch (error) {
    console.log('Unable to delete card', error)
  }
}

export const createNextPhase = async ({
  id,
  model,
  phaseNumber,
  incrementNumber
}) => {
  try {
    // phase number is already incremented
    const mt = phases[model]
    const phaseName = mt.routes[phaseNumber]

    const phasesRef =
      model === 'incremental'
        ? collection(db, `phases/${id}/${incrementNumber}/${phaseName}/${id}`)
        : collection(db, `phases/${id}/${phaseName}`)

    //  Later we add todos
    const welcomeCard = {
      title: 'Welcome',
      content:
        "Hope your excited to work on your new project, Feel free to delete this when you're ready",
      completed: false,
      timestamp: serverTimestamp()
    }
    await addDoc(phasesRef, welcomeCard)
  } catch (error) {
    console.log('Unable to move onto the next phase', error)
  }
}
