import Navbar from 'Components/Navbar'
import Home from 'Pages/Default/Home'
import ProjectLayout from 'Pages/Layouts/ProjectLayout'
import { Redirect, Route, Switch } from 'react-router'

const MainLayout = () => {
  return (
    <div className='bg-chinese_black text-white'>
      <Navbar />
      <div className='pt-16 p-5 min-h-screen flex flex-col'>
        <Switch>
          <Route exact path='/home' component={Home} />
          <Route path='/project' component={ProjectLayout} />
          <Redirect exact from='/' to='/home' />
        </Switch>
      </div>
    </div>
  )
}

export default MainLayout
