import { getAuth, signOut } from '@firebase/auth'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import Popup from 'reactjs-popup'

const Navbar = () => {
  const history = useHistory()
  const auth = getAuth()

  const logOutUser = () => {
    signOut(auth)
      .then(() => {
        history.push('/login')
        toast.success('Logged out successfully. We will miss you 😢')
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className='fixed top-0 z-50 w-full py-2 bg-dark_black z-60'>
      <div className='flex justify-between items-center relative px-10'>
        <div className='text-sky_blue font-bold font-poppins text-xl'>
          Skye.
        </div>
        <div className='flex gap-x-2 md:gap-x-4 items-center justify-end'>
          <Popup
            trigger={
              <img
                referrerPolicy='no-referrer'
                src={auth.currentUser?.photoURL}
                alt='profile-pic'
                className='rounded-full w-12 h-12 cursor-pointer'
              />
            }
            position='bottom right'
            on='click'
            closeOnDocumentClick
            mouseEnterDelay={0}
            contentStyle={{
              padding: 0,
              margin: '12px 0',
              border: 'none',
              backgroundColor: '#393E46',
              borderRadius: '10px',
              position: 'fixed'
            }}
            arrow={false}
          >
            <div className='w-40 text-white text-sm font-sora'>
              <div className='flex flex-col items-center gap-y-3 p-2'>
                <div className='w-full text-center mx-auto'>
                  <button
                    onClick={logOutUser}
                    className='cursor-pointer w-full text-xs font-poppins px-5 py-2 rounded-md font-bold font-sora text-white bg-ocean_blue border-ocean_blue hover:bg-blue-200 hover:text-ocean_blue transition-all duration-300 ease-in-out whitespace-nowrap'
                  >
                    Log Out
                  </button>
                </div>
              </div>
            </div>
          </Popup>
        </div>
      </div>
    </div>
  )
}

export default Navbar
