export const modelOptions = [
  {
    label: 'Incremental Model',
    value: 'incremental'
  },
  {
    label: 'V Model',
    value: 'v'
  },
  {
    label: 'Waterfall Model',
    value: 'waterfall'
  }
]
