import { configureStore } from '@reduxjs/toolkit'
import loadingReducer from './slices/loading'
import authReducer from './slices/auth'
import projectReducer from './slices/project'

const store = configureStore({
  reducer: {
    auth: authReducer,
    loading: loadingReducer,
    project: projectReducer
  }
})

export default store
