import { phases } from 'Data/phases'
import { createNextPhase, updateProjectStatus } from 'Firestore/cards'
import { useDispatch, useSelector } from 'react-redux'
import Popup from 'reactjs-popup'
import {
  incrementIncrementNumber,
  incrementPhaseNumber
} from 'Redux/slices/project'
import { ReactComponent as ChervonRight } from 'Assets/chervon-right.svg'
import { useState } from 'react'
import { createNextIncrement, deleteExistingProject } from 'Firestore/projects'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'

const NextPhase = () => {
  const project = useSelector((state) => state.project)
  const dispatch = useDispatch()
  const mt = phases[project.model]
  const isFinalPhase = project.phaseNumber === mt.routes.length - 1

  const [option, setOption] = useState('phase')

  const history = useHistory()

  const handleProjectCompletion = async (id) => {
    try {
      // delete project
      await deleteExistingProject(id)
      toast.success('Congratulations on completing your project! 🎊')
      // redirect to home page
      history.push('/home')
    } catch (error) {
      console.log('Error occured while deleting the project', error)
    }
  }

  const handleConfirm = async () => {
    try {
      const fmt = { ...project }
      if (option === 'phase') {
        fmt.phaseNumber++
        await createNextPhase(fmt)
        dispatch(incrementPhaseNumber())
      } else if (option === 'finish') {
        await handleProjectCompletion(fmt.id)
      } else if (option === 'increment') {
        fmt.phaseNumber = 0
        fmt.incrementNumber++
        await createNextIncrement(fmt)
        dispatch(incrementIncrementNumber())
      }
      await updateProjectStatus(fmt)
    } catch (error) {
      console.log('Unable to update confirmed next phase')
    }
  }

  return (
    <div>
      <Popup
        trigger={
          <div className='absolute top-4 right-0'>
            {!isFinalPhase ? (
              <button
                onClick={() => setOption('phase')}
                className='text-xs flex px-6 py-1.5 bg-ocean_blue rounded-sm font-poppins'
              >
                Next Phase
                <ChervonRight className='ml-4 w-4 h-4' />
              </button>
            ) : (
              <div className='flex'>
                {project.model === 'incremental' && (
                  <button
                    onClick={() => setOption('increment')}
                    className='text-xs flex px-6 py-1.5 font-poppins bg-grey_black rounded-full mr-4'
                  >
                    Next Increment
                    <ChervonRight className='ml-4 w-4 h-4' />
                  </button>
                )}
                <button
                  onClick={() => setOption('finish')}
                  className='text-xs px-6 py-1.5 font-poppins bg-ocean_blue rounded-sm'
                >
                  Finish Project
                </button>
              </div>
            )}
          </div>
        }
        modal
        contentStyle={{
          minWidth: '400px'
        }}
        overlayStyle={{
          backgroundColor: 'black'
        }}
      >
        {(close) => (
          <div className='bg-dark_black text-white px-6 py-10 rounded-md border-2 border-water_blue font-inter text-sm w-full'>
            <div
              onClick={close}
              className='absolute top-5 right-5 cursor-pointer text-lg'
            >
              &times;
            </div>
            <div className='text-center'>
              <div className='pb-3 text-sky_blue font-bold text-lg'>
                Move to the next phase?
              </div>
              <div className='text-sm'>Are you sure this can't be undone</div>
            </div>
            <div className='flex justify-center gap-x-4 pt-5'>
              <div>
                <button
                  type='submit'
                  onClick={() => {
                    handleConfirm().then(() => {
                      close()
                    })
                  }}
                  className='bg-grey_black px-10 py-1 rounded-md text-sm'
                >
                  Yes
                </button>
              </div>
              <div>
                <button
                  onClick={close}
                  className='bg-water_blue px-10 py-1 text-sm rounded-md'
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}
      </Popup>
    </div>
  )
}

export default NextPhase
