import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged
} from 'firebase/auth'
import GoogleLogo from 'Assets/GoogleLogo.svg'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setLoading } from 'Redux/slices/loading'
import { setAccessToken } from 'Redux/slices/auth'
import { useHistory } from 'react-router-dom'

const OAuth = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const auth = getAuth()

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      dispatch(setLoading(true))
      if (user) {
        const { accessToken } = user
        dispatch(setAccessToken(accessToken))
        history.push('/')
      } else {
        dispatch(setAccessToken(null))
      }
      dispatch(setLoading(false))
    })

    return () => unsubscribe()
  }, []); //eslint-disable-line

  const loginUser = () => {
    const auth = getAuth()
    const provider = new GoogleAuthProvider()

    signInWithPopup(auth, provider).catch((err) => {
      console.log(err)
    })
  }

  return (
    <div className='grid place-items-center w-screen h-screen bg-chinese_black'>
      <div className='px-3 py-3 flex flex-col justify-center items-center w-11/12 md:w-3/5 lg:w-2/5 rounded-xl'>
        <div className='font-poppins text-water_blue text-6xl py-6 font-bold'>
          Skye.
        </div>
        <button
          onClick={loginUser}
          className='bg-white google-login-btn flex items-center justify-center mb-3 rounded-md w-1/2'
        >
          <img
            src={GoogleLogo}
            alt='google-logo'
            className='w-8 sm:w-10 lg:w-12'
          />
          {/* TODO: Change to roboto font later */}
          <div className='text-xs sm:text-base md:text-lg font-inter px-3'>
            Sign in with Google
          </div>
        </button>
      </div>
    </div>
  )
}

export default OAuth
