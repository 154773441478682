import { addNewCard } from 'Firestore/cards'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useSelector } from 'react-redux'
import { ReactComponent as PlusIcon } from 'Assets/plus.svg'
import * as Yup from 'yup'

const AddCardForm = () => {
  const { id, phaseNumber, model, incrementNumber } = useSelector(
    (state) => state.project
  )

  return (
    <div className='relative w-1/3 mx-auto'>
      <Formik
        initialValues={{
          title: '',
          content: ''
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
          title: Yup.string().required('Required'),
          content: Yup.string().required('Required')
        })}
        onSubmit={(values, { resetForm }) => {
          addNewCard(values, model, id, phaseNumber, incrementNumber).then(() =>
            resetForm()
          )
        }}
      >
        <Form className='grid gap-y-2 bg-dark_black border border-sky_blue text-left px-10 py-4 rounded-md'>
          <div>
            <Field
              placeholder='Title'
              className='font-poppins px-3 py-2 outline-none text-lg w-full bg-dark_black text-white'
              name='title'
              type='text'
            />
            <ErrorMessage name='title'>
              {(p) => <div className='err-msg ml-3'>{p}</div>}
            </ErrorMessage>
          </div>
          <div>
            <Field
              name='content'
              as='textarea'
              rows={3}
              className='px-3 resize-none py-2 outline-none text-sm w-full bg-dark_black text-white'
              placeholder='Take a note...'
            />
            <ErrorMessage name='content'>
              {(p) => <div className='err-msg ml-3'>{p}</div>}
            </ErrorMessage>
          </div>
          <div className='absolute -bottom-5 right-10'>
            <button type='submit' className='bg-ocean_blue p-4 rounded-full'>
              <PlusIcon className='w-4 h-4' />
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default AddCardForm
