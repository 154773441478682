import Select from 'react-select'

const addSelectStyle = (provided) => ({
  ...provided,
  backgroundColor: '#393E46',
  color: 'white',
  borderRadius: '10px',
  border: 'none'
})

const SelectField = ({
  options,
  field,
  form,
  placeholder,
  disabled,
  isMulti
}) => {
  const getValue = () => {
    if (options) {
      return isMulti
        ? field.value === null
            ? null
            : options.filter((option) => field.value.indexOf(option.value) >= 0)
        : field.value === null
          ? null
          : options.find((option) => option.value === field.value)
    } else {
      return isMulti ? [] : ''
    }
  }

  return (
    <Select
      options={options}
      name={field.name}
      placeholder={placeholder}
      isDisabled={disabled}
      isMulti={isMulti}
      styles={{
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected
            ? '#1A74E2'
            : state.isFocused
              ? '#1F2123'
              : '#393E46',
          color: 'white',
          borderRadius: '4px'
        }),
        menu: addSelectStyle,
        placeholder: addSelectStyle,
        control: addSelectStyle,
        singleValue: addSelectStyle,
        dropdownIndicator: addSelectStyle
      }}
      value={getValue()}
      className='text-sm font-normal mt-0.5'
      onChange={(options) => {
        form.setFieldValue(
          field.name,
          isMulti ? options.map((item) => item.value) : options.value
        )
      }}
      onBlur={field.onBlur} // eslint-disable-line
    />
  )
}

export default SelectField
