import { createSlice } from '@reduxjs/toolkit'

const project = {
  title: '',
  model: '',
  phaseNumber: 0,
  id: '',
  incrementNumber: 1
}

export const projectSlice = createSlice({
  name: 'project',
  initialState: project,
  reducers: {
    setCurrentProject: (state, action) => {
      const { title, model, phaseNumber, id, incrementNumber } = action.payload
      state.title = title
      state.model = model
      state.phaseNumber = phaseNumber
      state.id = id
      state.incrementNumber = incrementNumber
    },
    incrementPhaseNumber: (state) => {
      state.phaseNumber = state.phaseNumber + 1
    },
    incrementIncrementNumber: (state) => {
      state.incrementNumber = state.incrementNumber + 1
      state.phaseNumber = 0
    }
  }
})

export const {
  setCurrentProject,
  incrementPhaseNumber,
  incrementIncrementNumber
} = projectSlice.actions

export default projectSlice.reducer
