import { collection, query, orderBy, onSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { db } from 'firebase'
import { phases } from 'Data/phases'
import AddCardForm from 'Containers/Forms/AddCardForm'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import PhaseNavbar from 'Components/PhaseNavbar'
// import { ReactComponent as TickIcon } from "Assets/tick.svg";
import { ReactComponent as BinIcon } from 'Assets/bin.svg'
import { deleteCard } from 'Firestore/cards'

const Phase = () => {
  const [cards, setCards] = useState([])
  const { id, phaseNumber, model, incrementNumber } = useSelector(
    (state) => state.project
  )
  const { increment, index } = useParams()

  const isCurrentPhase = index - '0' === phaseNumber
  const mt = phases[model]
  const routeToGo = mt.routes[index]

  useEffect(() => {
    const collectionRef =
      model === 'incremental'
        ? collection(db, `phases/${id}/${increment}/${routeToGo}/${id}`)
        : collection(db, `phases/${id}/${routeToGo}`)
    const q = query(collectionRef, orderBy('timestamp', 'desc'))
    const unsub = onSnapshot(q, (snapshot) =>
      setCards(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    )

    return unsub
  }, [index, id, model, increment, routeToGo])

  return (
    <div>
      <PhaseNavbar />
      {isCurrentPhase && <AddCardForm model={model} />}
      <div className='flex-auto mt-16 overflow-y-auto grid sm:grid-cols-2 lg:grid-cols-4 gap-6'>
        {cards?.map((card) => {
          const pathRef =
            model === 'incremental'
              ? `phases/${id}/${incrementNumber}/${routeToGo}/${id}`
              : `phases/${id}/${routeToGo}`

          return <Card key={card.id} {...card} pathRef={pathRef} />
        })}
      </div>
    </div>
  )
}

const Card = ({ pathRef, ...card }) => {
  return (
    <div className='bg-dark_black relative flex flex-col text-left p-8 rounded-md'>
      <div className='text-xl pb-4 font-bold text-water_blue font-poppins'>
        {card?.title}
      </div>
      <div className='my-2 flex-auto text-sm text-gray-400 rounded-full'>
        {card?.content}
      </div>
      <div className='absolute top-6 right-6'>
        <BinIcon
          className='w-4 h-4 cursor-pointer text-grey_black'
          onClick={() => deleteCard(pathRef, card)}
        />
      </div>
    </div>
  )
}

export default Phase
