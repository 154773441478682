import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import PrivateRoute from 'PrivateRoute'
import MainLayout from 'Pages/Layouts/MainLayout'
import OAuth from 'Pages/Auth/Oauth'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import LoadingScreen from 'Components/LoadingScreen'

const App = () => {
  return (
    <div className='App'>
      <Router>
        <ToastContainer
          theme='colored'
          toastClassName='text-sm'
          bodyClassName='font-poppins'
          position='top-center'
          autoClose={3000}
        />
        <LoadingScreen />
        <div className='font-inter'>
          <Switch>
            <Route exact path='/login' component={OAuth} />
            <PrivateRoute path='/' component={MainLayout} />
          </Switch>
        </div>
      </Router>
    </div>
  )
}

export default App
