import { phases } from 'Data/phases'
import { useRef, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'

const TabLink = ({ to, text }) => {
  return (
    <NavLink
      to={to}
      exact
      className='px-4 py-2 rounded-sm font-poppins bg-dark_black flex place-items-center mx-1'
      activeClassName='bg-ocean_blue text-white'
    >
      <span className='text-xs'>{text}</span>
    </NavLink>
  )
}

const PhaseNavbar = () => {
  const { phaseNumber, model, incrementNumber } = useSelector(
    (state) => state.project
  )
  const mt = phases[model]
  const phasesReached = useRef([])
  const incrementsReached = useRef([1])

  const { increment } = useParams()

  useEffect(() => {
    if (model === 'incremental') {
      const inc = []
      for (let i = 1; i <= incrementNumber; i++) {
        inc.push(i)
      }
      incrementsReached.current = inc
    }
  }, [incrementNumber, model, phaseNumber])

  useEffect(() => {
    let count = phaseNumber
    if (increment < incrementNumber) {
      count = mt.routes.length - 1
    }
    const ph = []
    for (let i = 0; i <= count; i++) {
      ph.push(i)
    }
    phasesReached.current = ph
  }, [increment, incrementNumber, mt.routes.length, phaseNumber])

  return (
    <div className='lg:w-1/2 mx-auto mb-10'>
      {model === 'incremental' ? (
        <>
          <div className='flex justify-center'>
            {incrementsReached.current.map((inc, index) => (
              <TabLink
                key={index}
                to={`/project/${inc}/0`}
                text={`Increment ${inc}`}
              />
            ))}
          </div>
          <div className='flex justify-center mt-3'>
            {increment &&
              phasesReached.current.map((ph, index) => (
                <TabLink
                  key={index}
                  to={`/project/${increment}/${ph}`}
                  text={mt.cycle[ph]}
                />
              ))}
          </div>
        </>
      ) : (
        <div className='flex justify-center'>
          {phasesReached.current.map((ph, index) => (
            <TabLink key={index} to={`/project/${ph}`} text={mt.cycle[ph]} />
          ))}
        </div>
      )}
    </div>
  )
}

export default PhaseNavbar
