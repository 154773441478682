export const REQUIREMENT = 'Requirement Analysis'
export const DESIGN = 'Design'
export const CODING = 'Coding'
export const TESTING = 'Testing'
export const REVIEWS = 'Reviews'

// Models available as of now
export const INCREMENTAL = 'Incremental Model'
export const VMODEL = 'V Model'
export const WATERFALL = 'Waterfall Model'

export const phases = {
  incremental: {
    name: 'Incremental Model',
    cycle: ['Requirement Analysis', 'Design', 'Implementation', 'Testing'],
    routes: ['requirement', 'design', 'implementation', 'testing']
  },
  waterfall: {
    name: 'Waterfall Model',
    cycle: [
      'Requirement Analysis',
      'System Design',
      'Implementation',
      'Testing',
      'Maintenance'
    ],
    routes: [
      'requirement',
      'design',
      'implementation',
      'testing',
      'maintenance'
    ]
  },
  v: {
    name: 'V Model',
    cycle: [
      'Requirement Analysis',
      'System Design',
      'Architectural Design',
      'Module Design',
      'Coding',
      'Unit Testing',
      'Integration Testing',
      'System Testing',
      'Acceptance Testing'
    ],
    routes: [
      'requirement',
      'system-design',
      'architectural-design',
      'module-design',
      'coding',
      'unit-testing',
      'integration-testing',
      'system-testing',
      'acceptance-testing'
    ]
  }
}
