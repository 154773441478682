import SelectField from 'Components/SelectField'
import { modelOptions } from 'Data/dropdownOptions'
import { addNewProject } from 'Firestore/projects'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

const AddProjectForm = ({ onClose }) => {
  return (
    <div className='bg-dark_black px-6 py-10 rounded-md border-2 border-water_blue font-inter text-sm w-full'>
      <div
        onClick={onClose}
        className='absolute top-5 right-5 cursor-pointer text-lg text-white'
      >
        &times;
      </div>
      <div>
        <Formik
          initialValues={{
            title: '',
            model: null
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('Required'),
            model: Yup.string().nullable().required('Required')
          })}
          onSubmit={(values) => {
            addNewProject(values).then(() => {
              onClose()
            })
          }}
        >
          <Form className='grid gap-y-4'>
            <div>
              <label className='formikLabel' htmlFor='title'>
                Project Name
              </label>
              <Field className='formikInput' name='title' type='text' />
              <ErrorMessage name='title'>
                {(p) => <div className='err-msg'>{p}</div>}
              </ErrorMessage>
            </div>
            <div>
              <label className='formikLabel' htmlFor='model'>
                Model
              </label>
              <Field
                name='model'
                component={SelectField}
                options={modelOptions}
                placeholder='Enter Model'
              />
              <ErrorMessage name='model'>
                {(p) => <div className='err-msg'>{p}</div>}
              </ErrorMessage>
            </div>
            <div className='flex justify-center'>
              <button
                type='submit'
                className='bg-ocean_blue font-poppins font-bold text-white px-4 py-2 rounded-md mt-2 text-xs'
              >
                Create
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default AddProjectForm
