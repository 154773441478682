import { phases } from 'Data/phases'
import { db } from 'firebase'
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  serverTimestamp
} from 'firebase/firestore'
import { toast } from 'react-toastify'

export const addNewProject = async ({ title, model }) => {
  try {
    // model type
    const mt = phases[model]

    const metaRef = collection(db, 'meta')
    const metaPayload = {
      title,
      model,
      phaseNumber: 0,
      incrementNumber: 1, // this will be used in incremental model
      timestamp: serverTimestamp()
    }
    const docRef = await addDoc(metaRef, metaPayload)
    const phaseName = mt.routes[0]

    // route if incremental
    const phasesRef =
      model === 'incremental'
        ? collection(db, `phases/${docRef.id}/1/${phaseName}/${docRef.id}`)
        : collection(db, `phases/${docRef.id}/${phaseName}`)

    //  Later we add todos
    const welcomeCard = {
      title: 'Hey!',
      content:
        "Welcome to the phase. Feel free to delete this card when you're ready 😀",
      timestamp: serverTimestamp()
    }

    await addDoc(phasesRef, welcomeCard)
  } catch (error) {
    console.log('Unable to create a new project', error)
  }
}

export const createNextIncrement = async (project) => {
  try {
    // changes in meta
    // incremented incrementNumber and phaseNumber set to 0
    const fmt = { ...project }
    const mt = phases[project.model]
    const phaseName = mt.routes[project.phaseNumber]

    updateExistingProject(project)

    // creating new increment phase
    const phasesRef = collection(
      db,
      `phases/${project.id}/${fmt.incrementNumber}/${phaseName}/${project.id}`
    )

    const welcomeCard = {
      title: 'Hey!',
      content:
        "You have successfully the next increment of your project cycle. Kudos!. Feel free to delete this card when you're ready 😀",
      timestamp: serverTimestamp()
    }

    await addDoc(phasesRef, welcomeCard)
  } catch (error) {
    console.log(error)
    toast.error('Unable to create next increment!', error)
  }
}

export const updateExistingProject = async (project) => {
  try {
    const { id, ...details } = project
    const docRef = doc(db, 'meta', id)
    const payload = { ...details, timestamp: serverTimestamp() }
    updateDoc(docRef, payload)
  } catch (error) {
    console.log('Unable to update the existing project', error)
  }
}

export const deleteExistingProject = async (id) => {
  try {
    // delete meta
    const metaRef = doc(db, 'meta', id)
    // delete phases
    const phasesRef = doc(db, 'phases', id)
    await deleteDoc(metaRef)
    await deleteDoc(phasesRef)
  } catch (error) {
    console.log('Unable to delete the project', error)
  }
}
