import { createSlice } from '@reduxjs/toolkit'

const auth = {
  accessToken: null,
  isLoggedIn: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: auth,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload
      state.isLoggedIn = true
    }
  }
})

export const { setAccessToken } = authSlice.actions

export default authSlice.reducer
