import { ReactComponent as ChervonLeft } from 'Assets/chervon-left.svg'
import NextPhase from 'Containers/Modals/NextPhase'
import { phases } from 'Data/phases'
import Phase from 'Pages/Default/Phase'
import { useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useHistory
} from 'react-router'

const ProjectLayout = () => {
  const { title, model, phaseNumber, incrementNumber } = useSelector(
    (state) => state.project
  )
  const { url } = useRouteMatch()
  const mt = phases[model]

  const history = useHistory()

  return (
    <div className='relative'>
      <div
        onClick={() => history.push('/home')}
        className='absolute cursor-pointer top-6 left-0 flex items-center'
      >
        <ChervonLeft className='w-4 h-4' />
        <span className='ml-2 text-xs'>Back</span>
      </div>

      <div className='py-6 flex-initial font-bold text-5xl text-sky_blue font-poppins'>
        {title}
      </div>
      <div className='text-xs mb-4 text-water_blue font-semibold'>
        {mt?.name}
      </div>
      <NextPhase />
      <Switch>
        {model === 'incremental' ? (
          <>
            <Route path={`${url}/:increment/:index`} component={Phase} />
            <Redirect
              exact
              from={url}
              to={`${url}/${incrementNumber}/${phaseNumber}`}
            />
          </>
        ) : (
          <>
            <Route path={`${url}/:index`} component={Phase} />
            <Redirect exact from={url} to={`${url}/${phaseNumber}`} />
          </>
        )}
      </Switch>
    </div>
  )
}

export default ProjectLayout
