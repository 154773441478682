import { collection, query, orderBy, onSnapshot } from 'firebase/firestore'
import AddProjectForm from 'Containers/Forms/AddProjectForm'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { db } from 'firebase'
import { phases } from 'Data/phases'
import { useDispatch } from 'react-redux'
import { setCurrentProject } from 'Redux/slices/project'

const Home = () => {
  const [projects, setProjects] = useState([])

  useEffect(() => {
    const collectionRef = collection(db, 'meta')
    const q = query(collectionRef, orderBy('timestamp', 'desc'))
    const unsub = onSnapshot(q, (snapshot) =>
      setProjects(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    )

    return unsub
  }, []); //eslint-disable-line

  return (
    <div className='relative'>
      <div className='py-6 flex-initial font-bold text-3xl text-sky_blue font-poppins'>
        Your Projects.
      </div>
      <Popup
        trigger={
          <div className='absolute top-4 right-0'>
            <button className='text-sm font-poppins px-6 py-3 bg-ocean_blue rounded-md'>
              Create Project +
            </button>
          </div>
        }
        modal
        contentStyle={{
          maxWidth: '320px',
          background: 'white',
          borderRadius: '12px'
        }}
        overlayStyle={{
          background: 'rgba(0,0,0,0.5)'
        }}
      >
        {(close) => <AddProjectForm onClose={close} />}
      </Popup>
      <div className='flex-auto overflow-y-auto grid sm:grid-cols-2 lg:grid-cols-4 gap-6'>
        {projects?.map((project) => (
          <ProjectsCard key={project.id} {...project} />
        ))}
      </div>
    </div>
  )
}

const ProjectsCard = ({ title, model, id, phaseNumber, incrementNumber }) => {
  const mt = phases[model]

  const dispatch = useDispatch()

  return (
    <Link
      to='/project'
      onClick={() =>
        dispatch(
          setCurrentProject({ title, model, id, phaseNumber, incrementNumber })
        )}
    >
      <div className='bg-dark_black py-6 px-4 rounded-md font-poppins'>
        <div className='text-4xl py-16 font-bold text-water_blue'>{title}</div>
        <div className='my-6 text-sm text-sky_blue rounded-full font-semibold'>
          {mt?.name}
        </div>
        <div className='text-gray-500 font-semibold text-xs'>
          {mt?.cycle[phaseNumber]}
        </div>
      </div>
    </Link>
  )
}

export default Home
