import { useSelector } from 'react-redux'

const LoadingScreen = () => {
  const { loading } = useSelector((state) => state.loading)

  return (
    <div
      className={`fixed top-0 bottom-0 left-0 right-0 w-screen h-screen z-100 grid place-items-center bg-black ${
        loading ? 'visible' : 'invisible'
      } 'opacity-80'}`}
    >
      <div className='font-inter text-sm text-center text-white'>
        Loading...
      </div>
    </div>
  )
}

export default LoadingScreen
